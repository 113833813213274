import React from "react";

import classes from "./ExpirenceList.module.css";

import ops from "../../images/ops.jpeg";
import osl from "../../images/osl.jpeg";
import ims from "../../images/ims.jpeg";

import Expirence from "./Expirence";

const ExpirenceList = () => {
  return (
    <React.Fragment>
      <div data-aos="fade-right" className={classes.wrapper}>
        <h2 className={classes.title}>Experience</h2>
        <Expirence
          image="https://d2q79iu7y748jz.cloudfront.net/s/_squarelogo/256x256/6acc72522f9bec3e1da48768d4225094"
          jobTitle="Consultant"
          company="Management Solutions"
          date="Sept 2023 - Present"
          location="Toronto, Ontario, Canada"
          discription="Involved in strategic IT and governance projects for top organizations, spanning areas like IT planning, PMO, and transformation. Expert in implementing cutting-edge technologies for digital transformation, including systems architecture, big data, and adept at managing risk and regulations in cybersecurity, data quality, and governance."
        />
        <Expirence
          image={ops}
          jobTitle="Junior Programmer"
          company="Ontario Treasury Board Secretariat"
          date="Sept 2021 - Sept 2022"
          location="Oshawa, Ontario, Canada"
          discription="Provided application support for a portfolio of applications. Tasks included user account maintenance, troubleshooting application issues and data management. Provided general support to developers, project managers and managers as required. Actively participate in team projects in a variety of roles throughout life cycle - analysis, development, testing, implementation."
        />
        <Expirence
          image={osl}
          jobTitle="Electronic Sales Associate"
          company="OSL Retail Services"
          date="Jul 2020 - Jul 2021"
          location="Oakville, Ontario"
          discription="In my role, I was responsible for managing photo orders and promoting select electronic products. Additionally, I spearheaded the creation of a comprehensive laptop inventory web application utilizing React.js and Firebase. This application effectively monitored the stock levels of laptops within the store, streamlining inventory management processes."
        />
      </div>
    </React.Fragment>
  );
};

export default ExpirenceList;
